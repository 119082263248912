.App {
    background-color: rgb(244, 247, 245);
    color: rgb(37, 37, 37);
    height: 100%;
    width: 100%;
    font-family: "Segoe UI", "Lucida Grande", "Lucida Sans", "Helvetica Neue", "Arial", "Helvetica", sans-serif;
}

.container-fluid {
    padding: 0;
}

.row {
    margin: 0;
}

.white {
    color: #f5f5ef;
}

.background-white {
    background-color: #f5f5ef;
}

.blue {
    color: #1f2839;
}

.background-red {
    background-color: #C41230;
}

.tan {
    color: #b69d74;
}

.background-grey {
    background-color: #9FA1A4;
}

.filter {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(31, 40, 57, 0.7);
    z-index: 50;
}

.inner-header-wrapper {
    background-position: center;
    background-size: cover;
    height: 15rem;
    width: 100%;
}

#desktop-phone {
    display: none;
}

#mobile-phone {
    display: block;
}

.text-left {
    text-align: left !important;
}

@media (min-width: 768px) {
    #desktop-phone {
        display: block;
    }
    #mobile-phone {
        display: none;
    }
}
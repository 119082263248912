.our-team-section-wrapper {
    padding: 1rem 3rem;
}

.aboutSectionWrapper {
    padding: 1rem 1.5rem;
    margin: 1rem;
    border-radius: '0.6rem';
}

.aboutSectionHeader {
    padding: 0.5em 0;
    text-align: center;
    font-size: 2em;
}

.aboutSectionImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 18em;
    width: 18em;
    border-radius: 50%;
    margin: 0.5rem auto;
    box-shadow: 9px 5px 15px rgb(0 0 0 / 10%);
}

.aboutSectionText {
    text-align: justify;
}

.aboutSectionText p {
    text-indent: 2rem;
}

.aboutSectionText h5 {
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
}

.aboutSectionText ul {
    padding: 0.25rem 3rem;
    font-size: 0.9rem;
}

.experience li {
    list-style-type: none !important;
}

@media (max-width: 820px) {
    .our-team-section-wrapper {
        padding: 0 0.5rem;
    }
    .aboutSectionWrapper {
        margin: 1rem 0.25rem;
        padding: 1rem 1rem;
    }
    .aboutSectionImage {
        height: 14em;
        width: 14em;
    }
    .aboutSectionText ul {
        padding: 0.25rem 1rem;
        font-size: 0.9rem;
        text-align: left;
    }
}
.sloganWrapper {
    /* height: 16em; */
    padding-top: 2em;
    padding-bottom: 2em;
    /* background-image: linear-gradient(#C41230, #9FA1A4); */
    background-color: #f5f5ef;
    color: #C41230;
    border-top: #C41230 8px double;
    border-bottom: #C41230 8px double;
}

.sloganWrapper p {
    width: 60%;
    margin: 0 auto;
}

@media only screen and (max-width: 838px) {
    .sloganWrapper p {
        width: 80%;
    }
}

@media only screen and (max-width: 450px) {
    .sloganWrapper h2 {
        font-size: 1.5em;
    }
    .sloganWrapper p {
        font-size: 0.8em;
    }
}
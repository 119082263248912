footer {
    padding: 3em 0;
}

footer li {
    padding: 0 1rem;
    text-align: center;
}

footer li a,
footer h6 {
    font-weight: 100;
}

footer section {
    margin: 0.5em 0 1em 0;
    display: inline-block;
}

footer li a {
    color: #fff;
    padding: 0 1em;
    text-decoration: none !important;
}

footer li a:hover {
    color: #9FA1A4;
    text-decoration: underline !important;
}

@media (max-width: 820px) {
    footer li {
        padding: 1rem 0 0 0;
        font-size: 0.9rem;
    }
}
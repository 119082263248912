.about-wrapper {
    padding: 2rem 0;
}

.about-wrapper p {
    text-indent: 2rem;
    padding: 0 1.75rem;
    text-align: justify;
}

.card-images {
    height: 20em;
    width: 100%;
    margin: 1em auto;
    border-radius: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px) {
    .about-wrapper {
        padding: 0;
    }
    .card-images {
        height: 10em;
        width: 100%;
        margin: 1em auto;
    }
}
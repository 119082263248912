.galleryImage {
    /* width: 28rem; */
    height: 15rem;
    border-radius: 12px;
    padding: 0;
    margin: 1em auto;
    box-shadow: 5px 3px 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: background-image 750ms;
    transition: background-image 750ms;
    overflow: hidden;
}

.infoBackground {
    color: #fff;
    position: relative;
    width: 100%;
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    transition: top 750ms ease-out;
    height: 100%;
}

.infoBackground h3 {
    margin: 0;
    transition: padding 750ms ease-out;
}

.infoBackground p {
    width: 80%;
    margin: 0 auto 0.75em auto;
    font-size: 0.975em;
}

.infoBackground a {
    /* color: #fff; */
    font-weight: bold;
    font-size: 1em;
}

.infoBackground a:hover {
    /* color: blue; */
}

@media (max-width: 1024px) {
    .infoBackground p {
        font-size: 1em;
    }
}

@media (max-width: 600px) {
    .infoBackground p {
        font-size: 0.8em;
    }
}


/* @media (max-width: 1024px){
  .galleryImage{
    height: 175px;
    margin: 0.55em;
  }

  .infoBackground h3{
    font-size: 1.22em;
  }

  .infoBackground p{
    margin-bottom: 0;
    font-size: 0.8em;
  }

  .infoBackground a {
    font-size: 1em;
  }

}

@media (max-width: 768px){
  .galleryImage{
    height: 300px;
    margin: 0.5em 0;
    background-position: top;
  }

  .infoBackground h3{
    font-size: 2em;
  }

  .infoBackground p{
    font-size: 1.5em;
  }

  .infoBackground a {
    font-size: 1.5em;
  }

}

@media (max-width: 600px){
  .galleryImage{
    height: 250px;
  }

  .infoBackground h3{
    font-size: 1.4em;
  }

  .infoBackground p{
    font-size: 1em;
  }

  .infoBackground a {
    font-size: 1em;
  }

} */
#more {
    padding: 1em 6em;
}

.issuesWrapper {
    padding: 0 1em;
}

@media (max-width: 1200px) {
    #issuesInner {
        padding: 1em;
    }
    .issuesWrapper {
        padding: 0;
    }
}

@media (max-width: 768px) {
    #more {
        padding: 1em 1em;
    }
}